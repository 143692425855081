import React from 'react'
import { getCurrentLangKey } from 'ptz-i18n'
import { useSiteMetadata } from '../../../hooks/use-site-metadata'
import Layout from '../../../components/Layout/Layout'
import layoutStyles from '../../../components/Layout/Layout.module.scss'
import componentStyles from './IncreaseOnlineAdvertisingPage.module.scss'
import { getConfigByLanguage } from '../../../utils/common-utils'
import ShadowedContainer from '../../../components/ShadowedContainer/ShadowedContainer'
import Button from '../../../components/Button/Button'
import Annotation from '../../../components/Annotation/Annotation'
import CheckedText from '../../../components/CheckedText/CheckedText'
import CasesCard from '../../../components/CasesCard/CasesCard'

const IncreaseOnlineAdvertisingPage = ({ location }) => {
  const { langs, defaultLangKey } = useSiteMetadata().languages
  const url = location.pathname
  const language = getCurrentLangKey(langs, defaultLangKey, url)
  const config = getConfigByLanguage(language)
  const {
    backButton,
    moreCases,
    reducingUnfulfilledContracts,
    increaseOnlineAdvertising,
    deliverMoreCampaigns,
    boostSTR,
    whiteLabelForecasting,
  } = config.cases
  const {
    title,
    mainPoints,
    challenge,
    goals,
    solution,
    results,
    seo
  } = increaseOnlineAdvertising
  const list = [
    {
      title: reducingUnfulfilledContracts.title,
      url: reducingUnfulfilledContracts.url,
    },
    {
      title: deliverMoreCampaigns.title,
      url: deliverMoreCampaigns.url,
    },
    {
      title: boostSTR.title,
      url: boostSTR.url,
    },
    {
      title: whiteLabelForecasting.title,
      url: whiteLabelForecasting.url,
    },
  ]

  return (
    <Layout language={language} location={location} seo={seo}>
      <section>
        <div className={layoutStyles.container}>
          <Button className={componentStyles.backButton} href={backButton.url}>
            {backButton.text}
          </Button>
          <h1 className={componentStyles.title}>{title}</h1>
        </div>
      </section>
      <section className={componentStyles.mainPoints}>
        <div className={layoutStyles.container}>
          <ul className={componentStyles.mainPointsList}>
            {mainPoints.map((point, index) => (
              <li key={index} className={componentStyles.mainPointsItem}>
                <ShadowedContainer
                  className={componentStyles.shadowedContainer}
                >
                  <Annotation
                    className={componentStyles.mainPointsItemContent}
                    text={point}
                  />
                </ShadowedContainer>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className={componentStyles.challenge}>
        <div className={layoutStyles.container}>
          <h2 className={componentStyles.subtitle}>{challenge.title}</h2>
          <p className={componentStyles.paragraph}>{challenge.paragraph1}</p>
        </div>
      </section>
      <section className={componentStyles.goals}>
        <div className={layoutStyles.container}>
          <h2 className={componentStyles.subtitle}>{goals.title}</h2>
          <ol className={componentStyles.goalsList}>
            {goals.list.map((goal, index) => (
              <li className={componentStyles.goalsItem} key={index}>
                {goal}
              </li>
            ))}
          </ol>
        </div>
      </section>
      <section className={componentStyles.solution}>
        <div className={layoutStyles.container}>
          <h2 className={componentStyles.subtitle}>{solution.title}</h2>
          <p className={componentStyles.paragraph}>{solution.paragraph}</p>
          <p className={componentStyles.contentSubtitle}>{solution.subtitle}</p>
          <ul className={componentStyles.firmDotList}>
            {solution.list &&
              solution.list.map((item, index) => (
                <li key={index} className={componentStyles.firmDotListItem}>
                  {item}
                </li>
              ))}
          </ul>
        </div>
      </section>
      <section className={componentStyles.results}>
        <div className={layoutStyles.container}>
          <h2 className={componentStyles.subtitle}>{results.title}</h2>
          <p className={componentStyles.contentSubtitleBright}>{results.paragraph}</p>
          <ul className={componentStyles.resultsList}>
            {results &&
              results.list.map((title, index) => (
                <CheckedText
                  customClasses={{
                    containerClass: componentStyles.resultsItem,
                    titleClass: componentStyles.resultsItemTitle,
                    iconClass: componentStyles.resultsIcon,
                  }}
                  component={'li'}
                  title={title}
                  key={`item-${index}`}
                />
              ))}
          </ul>
        </div>
      </section>
      <section className={componentStyles.moreCases}>
        <div className={layoutStyles.container}>
          <h2 className={componentStyles.subtitle}>{moreCases.title}</h2>
          <div className={componentStyles.moreCasesWrapper}>
            <ul className={componentStyles.moreCasesList}>
              {list.map(({ title, url }, index) => (
                <li className={componentStyles.moreCasesItem} key={index}>
                  <CasesCard title={title} url={url} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IncreaseOnlineAdvertisingPage
